import { useEffect, useState, useRef } from 'react';
import { getAuthStatus } from '../api';
import { User } from '../types';


export function useFetchUser() {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true); // Ajouter une référence pour suivre le montage du composant


  useEffect(() => {
    setLoading(true);
    getAuthStatus()
      .then(({ data }) => {
        if (isMounted.current) { // Vérifier si le composant est toujours monté
          setUser(data);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setError(err);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    return () => {
      isMounted.current = false; // Marquer comme démonté
    };
  }, []);

  return { user, error, loading };
}
