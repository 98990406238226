import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import { useFetchUser } from '/home/Dev/dashboard/central/react/src/utils/hooks/useFetchUser'; // Assurez-vous que le chemin d'importation est correct
import { BarLoader } from 'react-spinners';

const Licence = () => {
  const navigate = useNavigate();
  const { user, loading, error } = useFetchUser();
  const [additionalLoading, setAdditionalLoading] = useState(true);
  const isMounted = useRef(true);

  // Données factices pour le développement
  const mockData = [
    { id: 1, nomServeur: 'Serveur 1', ipServeur: '192.168.1.1' },
    { id: 2, nomServeur: 'Serveur 2', ipServeur: '192.168.1.2' },
    { id: 3, nomServeur: 'Serveur 3', ipServeur: '192.168.1.3' },
  ];

  // État local pour les données et la modal
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(mockData);
  const [editedData, setEditedData] = useState({ id: '', nomServeur: '', ipServeur: '' });

  // Définissez le type de vos données de rangée
  interface RowData {
    id: number;
    nomServeur: string;
    ipServeur: string;
  }

  // Utilisez useEffect pour vérifier l'état de l'utilisateur
  useEffect(() => {
    // Timer pour simuler un chargement supplémentaire
    const timer = setTimeout(() => {
      if (isMounted.current) {
        setAdditionalLoading(false);
      }
    }, 3000);

    return () => {
      isMounted.current = false;
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    // Redirection si l'utilisateur n'est pas connecté ou n'a pas de discordId
    if (!additionalLoading && (loading || !user?.discordId)) {
      navigate('/home');
    }
  }, [user, loading, navigate, additionalLoading]);

  // Gestion des états de chargement et d'erreur
  if (loading || additionalLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <BarLoader color="white" />
      </div>
    );
  }
  

  if (error) {
    return <div>Error: Une erreur s'est produite. Veuillez réessayer plus tard.</div>;
  }

  const handleEdit = (row: RowData) => { // Ajoutez le type RowData ici
    setEditedData({
      id: row.id.toString(),
      nomServeur: row.nomServeur,
      ipServeur: row.ipServeur,
    });
    setShowModal(true); // Pour afficher la modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmEdit = () => {
    // Mettez à jour les données (à implémenter selon votre logique)
    const updatedData = data.map((item) =>
      item.id === parseInt(editedData.id, 10)
        ? {
            ...item,
            nomServeur: editedData.nomServeur,
            ipServeur: editedData.ipServeur,
          }
        : item
    );

    setData(updatedData);
    setEditedData({ id: '', nomServeur: '', ipServeur: '' });
  };

  return (
    
    <div className="table-container">
      <h2>Vos licences</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nom du serveur</th>
            <th>IP du serveur</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.nomServeur}</td>
              <td>{row.ipServeur}</td>
              <td>
                <button className="button-edit" onClick={() => handleEdit(row)}>
                  <FontAwesomeIcon icon={faEdit} /> Éditer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* La Modal est appelée ici et reçoit le formulaire d'édition comme enfant */}
      <Modal show={showModal} onClose={handleCloseModal}>
        <div>
          <h3>Éditer les données</h3>
          <input
            type="text"
            placeholder="Nom du serveur"
            value={editedData.nomServeur}
            onChange={(e) => setEditedData({ ...editedData, nomServeur: e.target.value })}
          />
          <input
            type="text"
            placeholder="IP du serveur"
            value={editedData.ipServeur}
            onChange={(e) => setEditedData({ ...editedData, ipServeur: e.target.value })}
          />
          <button onClick={() => { handleConfirmEdit(); handleCloseModal(); }}>Confirmer</button>
        </div>
      </Modal>
    </div>
  );
};

export default Licence;
