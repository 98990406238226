import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { AppBar } from './components/AppBar';
import { Spinner } from './components/Spinner';
import { CategoryPage } from './pages/CategoryPage';
import { GuildAnalyticsPage } from './pages/GuildAnalyticsPage';
import { GuildBansPage } from './pages/GuildBansPage';
import { GuildPrefixPage } from './pages/GuildPrefixPage';
import { LoginPage } from './pages/LoginPage';
import { Server } from './pages/Server';
import Licence from '/home/Dev/dashboard/central/react/src/pages/Licence';
import { WelcomeMessagePage } from './pages/WelcomeMessagePage';
import { GuildContext } from './utils/contexts/GuildContext';
import { useFetchUser } from './utils/hooks/useFetchUser';
import { PartialGuild } from './utils/types';
import HomePage from '/home/Dev/dashboard/central/react/src/pages/Home';
import Invit from '/home/Dev/dashboard/central/react/src/pages/Invit';
import MenuPage from './pages/MenuPage';
import LoadingSpinner from '/home/Dev/dashboard/central/react/src/pages/LoadingSpinner.js'; // Importez le composant de chargement global
import { UserLoadingProvider, useUserLoading } from '/home/Dev/dashboard/central/react/src/pages/UserLoadingContext'; // Importez le contexte

function App() {
  const [guild, setGuild] = useState<PartialGuild>();
  const { user, loading, error } = useFetchUser();
  const [redirectMessage, setRedirectMessage] = useState(''); // Ajout d'un état pour le message

  const updateGuild = (guild: PartialGuild) => setGuild(guild);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <GuildContext.Provider value={{ guild, updateGuild, redirectMessage, setRedirectMessage }}>
      <Routes>
         {/* <Route path="/login" element={user ? <Navigate to="/home" /> : <LoginPage />} /> */}
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/invit" element={<Invit />} />
        <Route path="/server" element={<Server />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/licence" element={<Licence />} />
        {user && !error ? (
          <>
            <Route path="/dashboard/*" element={<AppBar />} />
            {/* Ne redirigez pas automatiquement vers /home ici */}
            <Route path="/dashboard/categories" element={<CategoryPage />} />
            <Route path="/dashboard/prefix" element={<GuildPrefixPage />} />
            <Route path="/dashboard/message" element={<WelcomeMessagePage />} />
            <Route path="/dashboard/analytics" element={<GuildAnalyticsPage />} />
            <Route path="/dashboard/bans" element={<GuildBansPage />} />
          </>
        ) : (
          <>
            {/* Routes pour les utilisateurs non connectés */}
            {/* <Route path="*" element={<Navigate to="/home" />} /> */}
          </>
        )}
      </Routes>
    </GuildContext.Provider>
  );
}

export default App;
