import React, { useContext, useEffect , useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { GuildContext } from '../utils/contexts/GuildContext';
import { FaDiscord,FaRobot , FaQuestionCircle } from 'react-icons/fa';
import { HomePageStyle, MainButton } from '../utils/styles';
import { IoIosAddCircle  } from "react-icons/io";
import { useFetchUser } from '/home/Dev/dashboard/central/react/src/utils/hooks/useFetchUser'; // Assurez-vous que le chemin d'importation est correct
import { BarLoader } from 'react-spinners';
import { MdDashboardCustomize } from "react-icons/md";
import cogoToast from 'cogo-toast';



//import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
    Container,
    Flex,
    Grid,
    Page,
    TextButton,
    Title,
  } from '../utils/styles';
  import {
    IoSettingsOutline,
    IoNewspaperOutline,
    IoInformationCircleOutline,
  } from 'react-icons/io5';

const HomePage = () => {
    const navigate = useNavigate();
    const { redirectMessage, setRedirectMessage } = useContext(GuildContext);
    const login = () => {
        window.location.href = 'https://discord.commandantplace.fr/api/auth/login';
      };
    const [additionalLoading, setAdditionalLoading] = useState(true);
    const isMounted = useRef(true);
    const { user, loading, error } = useFetchUser();
    const [isComponentMounted, setIsComponentMounted] = useState(true);

    useEffect(() => {
        if (redirectMessage) {
          setTimeout(() => setRedirectMessage(''), 5000); // Efface le message après 5 secondes
        }
      }, [redirectMessage, setRedirectMessage]);
      useEffect(() => {
        const timer = setTimeout(() => {
          if (isComponentMounted) {
            setAdditionalLoading(false);
          }
        }, 1000);
    
        return () => {
          setIsComponentMounted(false); // Définir le composant comme démonté lors du nettoyage
          clearTimeout(timer);
        };
      }, []);
    
      if (!isComponentMounted) {
        return null; // Le composant n'est plus monté, renvoyer null pour éviter les mises à jour de l'état
      }
    
    if (loading || additionalLoading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <BarLoader color="white" />
          </div>
        );
      }

  const handleDashboardClick = () => {
    if (user) {
      navigate('/menu');
    } else {
      // Afficher une notification pour demander à l'utilisateur de se connecter
      // Utilisez la bibliothèque de notification de votre choix
      cogoToast.warn("Vous devez vous connecter afin d'acceder à cette page", {hideAfter:5, position: 'top-center', heading: 'Attention', bar: { size: '0.5rem', color: '#f1c232',   }});
      //cogoToast.loading('Loading your data...').then(() => {
      //  cogoToast.success('Data Successfully Loaded');
      //});
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {redirectMessage && <div>{redirectMessage}</div>}

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <h1>Bienvenue sur notre site!</h1>

        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <MainButton onClick={login}>
            <FaDiscord size={40} color="5865F2" />
            <p style={{ fontSize: '18px', textAlign: 'center',margin: '18px'  }}>Se connecter avec Discord</p>
          </MainButton>

          <MainButton onClick={() => navigate('/invit')}>
            <FaRobot size={40} color="5865F2" />
            <p style={{ fontSize: '18px', textAlign: 'center', margin: '30px' }}>Inviter le bot</p>
          </MainButton>

        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MainButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleDashboardClick}>
              <MdDashboardCustomize size={40} color="5865F2" />
              <p style={{ fontSize: '18px', textAlign: 'center', margin: '30px' }}>Dashboard</p>
            </MainButton>
            </Grid>

      </div>
    </div>
  );
}

export default HomePage;
