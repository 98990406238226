import { useEffect } from 'react';

const Invit = () => {
  useEffect(() => {
    window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=879917192018296872&permissions=8&response_type=code&redirect_uri=https%3A%2F%2Fdiscord.commandantplace.fr%2Fapi%2Fauth%2Fredirect&scope=guilds+bot';
  }, []);

  return null; // Renvoie un élément vide, car la redirection se fait immédiatement
};

export default Invit;
