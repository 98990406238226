import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from '/home/Dev/dashboard/central/react/src/utils/hooks/useFetchUser'; // Assurez-vous que le chemin d'importation est correct
import { BarLoader } from 'react-spinners';
import { useContext} from 'react';
import { GuildMenuItem } from '../components/GuildMenuItem';
import { GuildContext } from '../utils/contexts/GuildContext';
import { useFetchGuilds } from '../utils/hooks/useFetchGuilds';
import { Container, Flex, Page } from '../utils/styles';
import { PartialGuild } from '../utils/types';
import { MainButton, Grid } from '../utils/styles';
import { FaDiscord,FaRobot , FaQuestionCircle } from 'react-icons/fa';
import { RiAdminFill } from "react-icons/ri";

const MenuPage = () => {
    const navigate = useNavigate();
    const { updateGuild } = useContext(GuildContext);
    const [additionalLoading, setAdditionalLoading] = useState(true);
  
    const [isMounted, setIsMounted] = useState(true); // Utiliser useState pour gérer isMounted
  
    //useEffect(() => {
    //  if (!userData && isMounted) {
    //    navigate('/home');
    //  }
  //
    //  return () => {
    //    setIsMounted(false); // Définir isMounted sur false lors du nettoyage
    //  };
    //}, [userData, isMounted, navigate]);
    
    

      return (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <h1>Administration</h1>

        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <MainButton onClick={() => navigate('/licence')}>
            <RiAdminFill size={40} color="5865F2" />
            <p style={{ fontSize: '18px', textAlign: 'center',margin: '30px'  }}>StitGuard AC</p>
          </MainButton>

          <MainButton onClick={() => navigate('/server')}>
            <FaDiscord size={40} color="5865F2" />
            <p style={{ fontSize: '18px', textAlign: 'center', margin: '30px' }}>Serveur Discord</p>
          </MainButton>
        </Grid>
    </div>
  );
}

export default MenuPage;

