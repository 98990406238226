import { useEffect, useState, useRef } from 'react';
import { getMutualGuilds } from '../api';
import { PartialGuild } from '../types';

export function useFetchGuilds() {
  const [guilds, setGuilds] = useState<PartialGuild[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const isMounted = useRef(true); // Ajouter une référence pour suivre le montage du composant

  useEffect(() => {
    setLoading(true);
    getMutualGuilds()
      .then(({ data }) => {
        if (isMounted.current) { // Vérifier si le composant est toujours monté
          setGuilds(data);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          console.log(err);
          setError(err);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    return () => {
      isMounted.current = false; // Marquer comme démonté
    };
  }, []);

  return { guilds, loading, error };
}
