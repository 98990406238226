import { PartialGuild } from '../types';
import { createContext, Dispatch, SetStateAction } from 'react';

type GuildContextType = {
  guild?: PartialGuild;
  updateGuild: (guild: PartialGuild) => void;
  redirectMessage: string; // Ajout de cette ligne
  setRedirectMessage: Dispatch<SetStateAction<string>>; // Et celle-ci
};

export const GuildContext = createContext<GuildContextType>({
  updateGuild: () => {},
  redirectMessage: '', // Valeur par défaut pour redirectMessage
  setRedirectMessage: () => {}, // Fonction vide par défaut pour setRedirectMessage
});