import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { GuildMenuItem } from '../components/GuildMenuItem';
import { GuildContext } from '../utils/contexts/GuildContext';
import { useFetchGuilds } from '../utils/hooks/useFetchGuilds';
import { Container, Flex, Page } from '../utils/styles';
import { PartialGuild } from '../utils/types';
import { MainButton, Grid } from '../utils/styles';
import { useFetchUser } from '../utils/hooks/useFetchUser';
import { localStorageService } from '/home/Dev/dashboard/central/react/src/utils/data/localStorageService';
import React from 'react';

export const Server = () => {
  const navigate = useNavigate();
  const { updateGuild } = useContext(GuildContext);
  const { guilds, loading, error } = useFetchGuilds();
  const [additionalLoading, setAdditionalLoading] = useState(true);

  const { user: userData, loading: userLoading, error: userError } = useFetchUser();

  const [user, setUser] = useState(() => {
    return localStorageService.getItem('user') || null;
  });

  useEffect(() => {
    localStorageService.setItem('user', user);
  }, [user]);

  useEffect(() => {
    if (!userData && !userLoading) {
      console.log('Utilisateur pas ecnore connecté');
      console.log(user);
    } else if (!loading && !additionalLoading) {
      // Logique pour charger les données des guildes, si nécessaire
    }
  }, [userData, userLoading, loading, additionalLoading, navigate]);
  
  // Utilisez useEffect pour arrêter le chargement supplémentaire une fois terminé
  useEffect(() => {
    if (additionalLoading && !loading) {
      setAdditionalLoading(false);
    }
  }, [loading, additionalLoading]);

  const handleClick = (guild: PartialGuild) => {
    updateGuild(guild);
    navigate('/dashboard/categories');
  };

  useEffect(() => {
    if (!loading && !additionalLoading) {
      if (!userData) {
        navigate('/home');
      }
    }
  }, [userData, userLoading, userError, loading, additionalLoading, navigate]);

  // Si l'utilisateur est connecté, n'effectuez pas la redirection
  if (userData) {
    return (
      <Page>
        <Container>
          <div>
            {loading || additionalLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <BarLoader color="white" />
              </div>
            ) : (
              <div>
                <h2 style={{ fontWeight: 300 }}>Select a Server</h2>
                {guilds &&
                  guilds.map((guild) => (
                    <div key={guild.id} onClick={() => handleClick(guild)}>
                      <GuildMenuItem guild={guild} />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </Container>
      </Page>
    );
  }

  return null; // Si l'utilisateur n'est pas connecté, ne renvoyez rien (équivaut à une redirection)
};
